<template>
  <div class="d-flex justify-content-between rounded border p-4">
    <div>
      <h4 class="fw-bold mb-5">{{ title }}</h4>
      <slot name="content"></slot>
    </div>
    <div class="bg-secondary p-4 border rounded question-card">
      <p class="fw-bold mb-2 question-title">{{ validationQuestion }}</p>
      <el-radio-group v-model="answer" class="d-flex flex-column" @change="$emit('answerSelect', $event)">
        <el-radio label="first" class="d-flex align-items-center pb-2 mx-0">
          <p>{{ firstAnswerText }}</p>
        </el-radio>
        <el-radio label="second" class="d-flex align-items-center mx-0">
          <p>{{ secondAnswerText }}</p>
        </el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    validationQuestion: { type: String, required: true },
    firstAnswerText: { type: String, required: true },
    secondAnswerText: { type: String, required: true },
  },
  data() {
    return {
      answer: '',
    };
  },
};
</script>

<style scoped lang="scss">
.question-card {
  width: 15.5625rem;
}
.question-title {
  word-break: normal;
}
</style>
