<template>
  <el-dialog
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    custom-class="rounded validation-warnings-modal"
  >
    <template #title>
      <div class="p-4 d-flex justify-content-between align-items-top">
        <div>
          <h2>{{ translate('title') }}</h2>
        </div>
        <Button type="icon" @click="() => close('PRESSED_X')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="px-4">
      <div class="py-1 mb-5">
        <div class="d-flex align-items-center rounded border informative-color py-4 px-4">
          <HazardOutlineIcon class="ms-2 text-warning" />
          <p class="fw-bold ms-1">
            {{ translate('headerCard.payAttention') }}
          </p>
          <p>
            {{ translate('headerCard.title') }}
          </p>
        </div>
      </div>
      <ValidationWarningCard
        v-for="warning in warningsToShow"
        :key="warning.key"
        :title="warning.title"
        :validation-question="warning.validationQuestion"
        :first-answer-text="warning.firstAnswerText"
        :second-answer-text="warning.secondAnswerText"
        class="mb-4"
        @answerSelect="(answer) => handleAnswerSelect(answer, warning)"
      >
        <template #content>
          <template v-if="warning.type === 'document.unique'">
            <Button type="link" as-link @click="handleSelectedDocumentModal(warning.context.documentIds[0])">
              <p>{{ translate('duplication.existingDocument') }}</p>
            </Button>
          </template>
          <template v-else-if="warning.type === 'document.format'">
            <template v-if="!!warning.context.documentNumber">
              <p class="text-typography-primary">
                {{ `${translate('format.documentNumber.recordedDocumentNumber')}: ${warning.context.documentNumber}` }}
              </p>
            </template>
          </template>
          <template v-else-if="warning.type === 'document.futureDate'">
            <template v-if="!!warning.context.issueDate">
              <p class="text-typography-primary">
                {{
                  $t('document.documentRecording.validation.warningsModal.futureDate.recordedIssueDate', {
                    issueDate: formatDate(warning.context.issueDate),
                  })
                }}
              </p>
            </template>
          </template>
          <template v-else-if="warning.type === 'document.taxRate'">
            <template v-if="!isNil(warning.context.taxRate)">
              <p class="text-typography-primary">
                {{
                  $t('document.documentRecording.validation.warningsModal.taxRate.recordedTaxRate', {
                    taxRate: warning.context.taxRate,
                  })
                }}
              </p>
            </template>
          </template>
          <template v-else-if="warning.type === 'document.allocationNumber'"> </template>
          <template v-else-if="warning.type === 'document.yearPastDate'">
            <template v-if="!!warning.context.issueDate">
              <p class="text-typography-primary">
                {{
                  $t('document.documentRecording.validation.warningsModal.yearPastDate.recordedIssueDate', {
                    issueDate: formatDate(warning.context.issueDate),
                  })
                }}
              </p>
            </template>
          </template>
          <template v-else-if="warning.type === 'document.monthPastDate'">
            <template v-if="!!warning.context.issueDate">
              <p class="text-typography-primary">
                {{
                  $t('document.documentRecording.validation.warningsModal.monthPastDate.recordedIssueDate', {
                    issueDate: formatDate(warning.context.issueDate),
                  })
                }}
              </p>
            </template>
          </template>
          <template v-else-if="warning.type === 'document.isDocumentBusinessValid'"> </template>
          <template v-else-if="warning.type === 'document.items.price'">
            <template v-if="!isNil(warning.context.itemPriceWarnings) && !isEmpty(warning.context.itemPriceWarnings)">
              <div v-for="(suspiciousItem, index) of warning.context.itemPriceWarnings" :key="index">
                <p>
                  {{ suspiciousItem.lineNumber }} - <span>{{ suspiciousItem.name }}</span>
                </p>
              </div>
            </template>
          </template>
        </template>
      </ValidationWarningCard>
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" @click="() => close('PRESSED_CANCEL')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleApplyClick">{{ $t('commons.apply') }}</Button>
      </div>
    </template>
    <DocumentModal
      v-if="selectedDocumentId"
      visible
      :is-digital-document-visible="showDigitalDocumentOnModal"
      :document-id="selectedDocumentId"
      @close="selectedDocumentId = null"
    />
  </el-dialog>
</template>

<script>
import { isNil, reject, isEmpty } from 'ramda';

import { CloseIcon, HazardOutlineIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { Button } from '@/modules/core';
import { options } from '@/locale/dateConfig';

import ValidationWarningCard from './ValidationWarningCard';

const hasWarningAnsweredCorrectly = ({ answer, warning }) => {
  switch (warning.type) {
    case 'document.unique':
      return answer === 'second';
    case 'document.format':
      return answer === 'first';
    case 'document.futureDate':
      return answer === 'first';
    case 'document.taxRate':
      return answer === 'first';
    case 'document.allocationNumber':
      return answer === 'first';
    case 'document.isDocumentBusinessValid':
      return answer === 'first';
    case 'document.yearPastDate':
      return answer === 'first';
    case 'document.monthPastDate':
      return answer === 'first';
    case 'document.items.price':
      return answer === 'first';
    default:
      return false;
  }
};

export default {
  components: { CloseIcon, HazardOutlineIcon, DocumentModal, ValidationWarningCard, Button },
  props: {
    warnings: { type: Array, required: true },
  },
  data() {
    return {
      showDigitalDocumentOnModal: true,
      selectedDocumentId: null,
      answers: [],
    };
  },
  computed: {
    warningsToShow() {
      return reject(isNil)([
        ...this.warnings.map((warning) => ({
          key: Math.random().toString(),
          type: warning.type,
          context: warning.context,
          ...this.getValidationWarningCardVariables(warning),
        })),
      ]);
    },
    allWarningsAnswered() {
      return this.warningsToShow.every((warning) => this.answers.some((answer) => answer.warning.key === warning.key));
    },
    allWarningsAnsweredCorrectly() {
      return this.answers.every(hasWarningAnsweredCorrectly);
    },
    warningsAnswers() {
      return this.answers.map(({ answer, warning }) => {
        const warningType = warning.type;
        const isValid = hasWarningAnsweredCorrectly({ answer, warning });
        return { [warningType]: isValid };
      });
    },
  },
  methods: {
    isEmpty,
    isNil,
    handleApplyClick() {
      if (!this.allWarningsAnswered) {
        this.$message.error(this.translate('messages.someWarningsNotAnswered'));
        return;
      }
      if (this.allWarningsAnsweredCorrectly) this.$emit('warnings-debunked', { warningsAnswers: this.warningsAnswers });
      else this.close();
    },
    translate(key) {
      return this.$t(`document.documentRecording.validation.warningsModal.${key}`);
    },
    close(trigger) {
      this.$emit('close', {
        trigger,
        warningsAnswers: this.warningsAnswers,
      });
    },
    handleAnswerSelect(answer, warning) {
      const existingAnswerIndex = this.answers.findIndex(
        (existingAnswer) => existingAnswer.warning.key === warning.key
      );
      if (existingAnswerIndex >= 0) this.answers[existingAnswerIndex].answer = answer;
      else
        this.answers.push({
          answer,
          warning,
        });
    },
    getValidationWarningCardVariables(warning) {
      switch (warning.type) {
        case 'document.unique':
          return {
            title: this.translate('duplication.title'),
            validationQuestion: this.translate('duplication.question'),
            firstAnswerText: this.translate('duplication.sameDocument'),
            secondAnswerText: this.translate('duplication.differentDocument'),
          };
        case 'document.format':
          // eslint-disable-next-line no-case-declarations
          const field = warning.path[0];
          return {
            title: this.translate(`format.${field}.title`),
            validationQuestion: this.translate(`format.${field}.question`),
            firstAnswerText: this.translate(`format.${field}.recordedProperly`),
            secondAnswerText: this.translate(`format.${field}.recordedImproperly`),
          };
        case 'document.futureDate':
          return {
            title: this.translate('futureDate.title'),
            validationQuestion: this.translate('futureDate.question'),
            firstAnswerText: this.translate('futureDate.recordedProperly'),
            secondAnswerText: this.translate('futureDate.recordedImproperly'),
          };
        case 'document.yearPastDate':
          return {
            title: this.translate('yearPastDate.title'),
            validationQuestion: this.translate('yearPastDate.question'),
            firstAnswerText: this.translate('yearPastDate.recordedProperly'),
            secondAnswerText: this.translate('yearPastDate.recordedImproperly'),
          };
        case 'document.monthPastDate':
          return {
            title: this.translate('monthPastDate.title'),
            validationQuestion: this.translate('monthPastDate.question'),
            firstAnswerText: this.translate('monthPastDate.recordedProperly'),
            secondAnswerText: this.translate('monthPastDate.recordedImproperly'),
          };
        case 'document.taxRate':
          return {
            title: this.translate('taxRate.title'),
            validationQuestion: this.translate('taxRate.question'),
            firstAnswerText: this.translate('taxRate.recordedProperly'),
            secondAnswerText: this.translate('taxRate.recordedImproperly'),
          };
        case 'document.allocationNumber':
          return {
            title: this.translate('allocationNumber.title'),
            validationQuestion: this.translate('allocationNumber.question'),
            firstAnswerText: this.translate('allocationNumber.recordedProperly'),
            secondAnswerText: this.translate('allocationNumber.recordedImproperly'),
          };
        case 'document.isDocumentBusinessValid':
          return {
            title: this.translate('isDocumentBusinessValid.title'),
            validationQuestion: this.translate('isDocumentBusinessValid.question'),
            firstAnswerText: this.translate('isDocumentBusinessValid.recordedProperly'),
            secondAnswerText: this.translate('isDocumentBusinessValid.recordedImproperly'),
          };
        case 'document.items.price':
          return {
            title: this.translate('itemPriceWarning.title'),
            validationQuestion: this.translate('itemPriceWarning.question'),
            firstAnswerText: this.translate('itemPriceWarning.recordedProperly'),
            secondAnswerText: this.translate('itemPriceWarning.recordedImproperly'),
          };
        default:
          return null;
      }
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    handleSelectedDocumentModal(documentId, showDigitalDocumentOnModal = true) {
      this.selectedDocumentId = documentId;
      this.showDigitalDocumentOnModal = showDigitalDocumentOnModal;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .validation-warnings-modal {
  height: 34rem;
  width: 46.5625rem;
  display: grid;
  grid-template-rows: 60px 1fr 3.875rem;

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
::v-deep .validation-warnings-modal .el-dialog__body {
  overflow-y: scroll;
}

.informative-color {
  background-color: change-color($color: $informative, $lightness: 98.1%);
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
.overflow-y {
  overflow-y: scroll;
}
</style>
