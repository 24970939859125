<template>
  <div>
    <el-upload
      action="#"
      :multiple="multiple"
      :http-request="uploadFile"
      :on-success="createDocument"
      :on-error="handleFileUploadFailure"
      :before-remove="() => false"
      :show-file-list="false"
      :drag="drag"
    >
      <slot>
        <Button type="primary" :size="size">
          {{ text || $tc('document.uploadFiles', multiple ? 10 : 1) }}
        </Button>
      </slot>
    </el-upload>

    <FileUploadSourceModal
      v-if="sourceModalOpen"
      :filename="selectedFileNames"
      @close="handleCloseSourceModal"
      @selectedSource="handleSelectedSource"
    />
  </div>
</template>

<script>
import { computed } from 'vue';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';
import { getDocumentFileUpload, createDocument } from '@/modules/document/compositions/documents-operations';
import FileUploadSourceModal from './FileUploadSourceModal';

import jsPDF from 'jspdf';
export default {
  components: { Button, FileUploadSourceModal },
  props: {
    multiple: { type: Boolean, default: false },
    text: { type: String, default: null },
    size: { type: String, default: undefined },
    drag: { type: Boolean, default: false },
  },
  setup() {
    const { currentTenant } = useTenancy();

    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const isManageUser = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.manage']);

    return {
      businessId: computed(() => currentTenant.value.id),
      isManageUser,
    };
  },
  data() {
    return {
      sourceModalOpen: false,
      selectedFileSource: undefined,
      files: [],
    };
  },
  computed: {
    selectedFileNames() {
      if (this.files.length <= 2) return this.files.map(({ file }) => file.name).join();
      return `${this.files.length} files`;
    },
  },
  methods: {
    handleCloseSourceModal() {
      this.sourceModalOpen = !this.sourceModalOpen;
      this.cleanSelectedFileSource();
      this.loadingClose();
    },

    async handleSelectedSource(source, fromEmail) {
      await Promise.all(this.files.map((request) => this.uploadFile(request, source, fromEmail)));
      this.cleanSelectedFileSource();
      this.loadingClose();
    },

    cleanSelectedFileSource() {
      this.files = [];
    },

    openSourceModal() {
      this.sourceModalOpen = true;
    },

    loadingStart() {
      if (!this.loaderHandler) this.loaderHandler = this.$loading();
      else {
        this.loadingClose();
        this.loaderHandler = this.$loading();
      }
    },
    loadingClose() {
      if (this.loaderHandler) {
        this.loaderHandler.close();
        this.loaderHandler = null;
      }
    },

    async uploadFile(request, source, fromEmail) {
      this.loadingStart();

      if (this.isManageUser && !source) {
        this.files.push(request);
        return this.openSourceModal();
      }

      if (!this.isManageUser) {
        source = 'direct';
      }

      try {
        const pdfFile = this.isNotPdfFile(request.file.type)
          ? await this.convertImageToPdf(request.file)
          : request.file;
        const { url, filePath, fields } = await getDocumentFileUpload({
          businessId: this.businessId,
          name: pdfFile.name,
          size: pdfFile.size,
        });
        const parsedFields = JSON.parse(fields);
        const formData = new FormData();
        Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
        formData.append('file', pdfFile);
        const response = await fetch(url, { method: 'POST', body: formData });
        if (response.ok) return request.onSuccess({ filePath, source, fromEmail });
        else request.onError();
      } catch (error) {
        this.loadingClose();
      }
    },
    async createDocument({ filePath, source, fromEmail } = {}) {
      if (!filePath) return;
      try {
        this.$emit('on-upload');
        this.$nextTick(async () => {
          try {
            await createDocument({ businessId: this.businessId, filePath, source, fromEmail });
            this.$message.success(this.$t('commons.messages.action.success'));
            this.$emit('on-success');
          } catch (error) {
            this.handleFileUploadFailure();
          }
        });
      } catch (error) {
        this.handleFileUploadFailure();
      } finally {
        this.loadingClose();
      }
    },

    handleFileUploadFailure() {
      this.loadingClose();
      this.$message.error(this.$t('commons.messages.action.error'));
      this.$emit('on-error');
    },

    async convertImageToPdf(file) {
      const pdfWidth = 210;
      const pdfHeight = 297;

      function fileToDataURL(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(file);
        });
      }

      function dataURLToPDFFile(dataURL, extension) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => {
            const pdfFile = createPDF(image, extension, file.name);
            resolve(pdfFile);
          };
          image.onerror = () => reject(new Error('Failed to load image'));
          image.src = dataURL;
        });
      }

      function createPDF(image, extension, fileName) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        // Rotate the canvas if width > height
        if (image.width > image.height) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.rotate(Math.PI / 2);
          ctx.translate(0, -canvas.width);
        }

        ctx.drawImage(image, 0, 0);

        const compressedDataURL = canvas.toDataURL(`image/${extension.toLowerCase()}`, 0.3);
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);

        const { imgWidth, imgHeight } = calculateImageDimensionsForPDF(canvas);

        pdf.addImage({
          imageData: compressedDataURL,
          format: extension,
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
          compression: 'FAST',
          rotation: 0,
        });

        const pdfBlob = pdf.output('blob');
        return new File([pdfBlob], `${fileName.split('.').slice(0, -1).join('.')}.pdf`, {
          type: 'application/pdf',
        });
      }

      function calculateImageDimensionsForPDF(canvas) {
        const aspectRatio = canvas.width / canvas.height;
        let imgWidth = pdfWidth;
        let imgHeight = pdfWidth / aspectRatio;

        if (imgHeight > pdfHeight) {
          imgHeight = pdfHeight;
          imgWidth = pdfHeight * aspectRatio;
        }

        return { imgWidth, imgHeight };
      }

      const extension = file.name.split('.').pop();
      const dataURL = await fileToDataURL(file);

      return dataURLToPDFFile(dataURL, extension);
    },

    isNotPdfFile(fileType) {
      return ['png', 'jpg', 'jpeg'].includes(fileType.split('/').pop().toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
